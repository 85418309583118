<template>
  <mdb-container fluid>
    <!-- Section: Edit Account -->
    <section class="section">
      <!-- First row -->
      <div class="row">
        <!-- First column -->
        <div class="col-lg-4 mb-4">
          <!--Card-->
          
          <div class="z-depth-0   card card-reversed card-cascade ">
            <!-- Card content -->
            <div class="card-body card-body-cascade text-center color-normal-reversed">
              <img
                style="width:135px"
                :src="($store.state.auth.user.avatar) ? $store.state.url+'storage/avatar/'+$store.state.auth.user.avatar: $store.state.url+'img/profile.png'"
                alt="User Photo"
                class="z-depth-1 mb-3 mx-auto rounded circle "
              />
              <h4>{{$store.state.auth.user.firstname}} <span class="text-uppercase">{{$store.state.auth.user.lastname}}</span></h4>
              <div class="company" style="font-weight:600;">
                {{$store.state.auth.site.name}}
              </div>
            </div>
            <!-- /.Card content -->
          </div>
          <!--/.Card-->
        </div>
        <!-- /.First column -->
        <!-- Second column -->
        <div class="col-lg-8 mb-4">
          <!--Card-->
          <div class=" d-flex justify-content-between align-items-center mb-n2">
          <h4 style="font-weight: 500">Mes informations</h4>
          </div>
          <hr class="" />
          <div class=" z-depth-0 card card-cascade ">
            <!-- Card content -->
            <div class="card-body card-reversed card-body-cascade text-center">
              <!-- Edit Form -->
              <form @submit.prevent="saveUser">
                <!--First row-->
                <div class="row">
                  <!--First column-->
                  <div class="col-md-12">
                    <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                     v-model="company" :disabled="true"  size="lg" outline label="Entreprise" placeholder="Entreprise" />
                  </div>
                </div>
                <!--/.First row-->
                <!--First row-->
                <div class="row">
                  <!--First column-->
                  <div class="col-sm-12 col-md-12 col-lg-6" outline>
                    <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                     :disabled="!$gate.checkTypeRole(['super-admin','admin!'])" v-model="form.firstname" outline   label="Prénoms" />
                  </div>
                  <!--Second column-->
                  <div class="col-sm-12 col-md-12 col-lg-6" outline>
                    <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                     :disabled="!$gate.checkTypeRole(['super-admin','admin!'])" v-model="form.lastname" outline   label="Nom" />
                  </div>
                </div>
                <!--/.First row-->
                <!--Second row-->
                <div class="row">
                  <!--First column-->
                  <div class="col-md-6">
                    <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                     :disabled="true"  v-model="email" outline label="Adresse Email" />
                  </div>
                  <!--Second column-->
                  <div class="col-md-6">
                    <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                     v-mask="'(###) ## ### ###'" placeholder="(000)00 000 000" v-model="form.phone" outline  label="Telephone" />
                  </div>
                </div>
                <!--/.Second row-->
                  <!--First column-->
                <!--/.Third row-->
                <!-- Fourth row -->
                <div class="row">
                  <div class="col-md-12 text-right">
                    <button type="submit" :disabled="submitBtn" class="btn btn-primary btn-md" > 
                      <span v-if="!submitBtn">Sauvegarder</span>
                      <span v-if="submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span v-if="submitBtn" class="pl-2">Loading...</span>
                    </button>
                  </div>
                </div>
                <!-- /.Fourth row -->
              </form>
              <!-- Edit Form -->
            </div>
            <!-- /.Card content -->
          </div>


          <div class="mt-5 d-flex justify-content-between align-items-center mb-n2">
          <h4 style="font-weight: 500">Changer Mot de passe</h4>
          </div>
          <hr class="" />
          <div class="card z-depth-0     card-cascade ">
             <div class="card-body card-reversed">
             <form @submit.prevent="chpwd()">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                   name="password" 
                    :validation="$store.state.user.errors.oldPassword ? true :false" :invalidFeedback="$store.state.user.errors.oldPassword"
                    :class="'mb-n2'"  v-model="formPassword.oldPassword" type="password" label="Ancien Mot de passe" outline />
                </div>
                <div class="col-sm-12 col-md-6">
                  <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                   name="password"  
                    :validation="$store.state.user.errors.password ? true :false" :invalidFeedback="$store.state.user.errors.password"
                   :class="'mb-n2'"  v-model="formPassword.password" type="password" label="Nouveau Mot de passe" outline />
                </div>
                <div class="col-sm-12 col-md-6">
                  <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                   name="password_c" 
                    :validation="$store.state.user.errors.password_c ? true :false" :invalidFeedback="$store.state.user.errors.password_c"
                    :class="'mb-n2'"  v-model="formPassword.password_c" type="password" label="Répétez Mot de passe" outline   />
                </div>
                <mdb-col sm="12" class="">
                  
                </mdb-col>
              </div>
              <div class="row">
                  <div class="col-md-12 text-right mt-4">
                    <button type="submit" :disabled="busyPassBtn"   class="btn btn-primary btn-md" > 
                      <span v-if="!busyPassBtn">Sauvegarder</span>
                      <span v-if="busyPassBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span v-if="busyPassBtn" class="pl-2">Loading...</span>
                    </button>
                  </div>
                </div>
                </form>
             </div>
          </div>
          <!--/.Card-->
        </div>
        <!-- /.Second column -->
      </div>
      <!-- /.First row -->
    </section>
    <!-- /.Section: Edit Account -->
  </mdb-container>
</template>

<script>
import { mdbContainer,  mdbInput,  } from "mdbvue";

export default {
  metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - MON PROFILE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
  name: "Basicv2",
  components: {
    mdbContainer,
   
    mdbInput,
    
  },
  data() {
    return {
      email:this.$store.state.auth.user.email,
      company:this.$store.state.auth.company.name,
      submitBtn:false,
      busyPassBtn:false,
      form:{
        id:this.$store.state.auth.user.id,
        phone:this.$store.state.auth.user.phone,
        firstname:this.$store.state.auth.user.firstname,
        lastname:this.$store.state.auth.user.lastname,
      },
      formPassword:{
        id:this.$store.state.auth.user.id,
        oldPassword:"",
        password:"",
        password_c:"",
      }
    };
  },
  methods:{
    async deleteAvatar(){
      let res = await this.$store.dispatch("swal/ultimate",{
          title:"Etes-vous sûre?",
          type:"warning",
          icon:"warning"
      });

      if (res.isDismissed) {
          return;
      }
    await this.$store.dispatch('user/deleteAvatar',this.$store.state.auth.user.id)
      .then((response) => {
          this.$notify({
               
              message: response.data.message,
              type: 'success'
          })
          this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
      })
      .catch(error =>{
          this.$notify({
                 
              message: error.response.data.message  || error.response.data.error,
              type: 'danger'
          })
      })
    },
    save_logo_success(response){
      this.$refs.upload.clearFiles()
          this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
          this.$notify({
               
              message:response.message,
              type:'success'
          })
    },
    save_logo_error(){
      this.$refs.upload.clearFiles()
          this.$notify({
                 
              message:"Erreur de validation. verifier le fichier, son type et sa taille",
              type:'danger'
      })
    },

    async saveUser() {
      this.$nprogress.start()

      this.form.is_active = this.$store.state.auth.user.is_active;
      this.form.is_archived = this.$store.state.auth.user.is_archived;
      this.form.site_id = this.$store.state.auth.user.site_id;

      this.$store.commit('user/SET_CLEAN')
      this.submitBtn = !this.submitBtn
    await this.$store.dispatch('user/updateProfile', this.form)
    .then((response) => {
      this.$nprogress.done()
      this.submitBtn = !this.submitBtn
      this.$notify({
           
          message: response.data.message,
          type: 'success'
      })
      this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
    })
    .catch((error) => {
      this.$nprogress.done()
      this.submitBtn = !this.submitBtn
      if (error.response.data.errors) {
        // 
      }
      this.$notify({
             
          message: error.response.data.message  || error.response.data.error,
          type: 'danger'
      })
    })  
    },

    async chpwd(){
           this.busyPassBtn = !this.busyPassBtn
            this.$store.commit('user/SET_CLEAN')
           this.$nprogress.start()
           await this.$store.dispatch('user/chpwd', this.formPassword)
           .then((response)=> {
                this.$nprogress.done()
                this.busyPassBtn = !this.busyPassBtn
                this.formPassword.oldPassword = ""
                this.formPassword.password = ""
                this.formPassword.password_c = ""
                this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
                this.$notify({
                     
                    message: response.data.message,
                    type: 'success'
                })
           })
           .catch((error) =>{
                this.busyPassBtn = !this.busyPassBtn
                if (error.response.data.errors) {
                  this.$store.commit('user/SET_OLD', error.response.data.errors.oldPassword)
                  this.$store.commit('user/SET_PWD', error.response.data.errors.password)
                  this.$store.commit('user/SET_PWDC', error.response.data.errors.password_c)
                }
                this.$nprogress.done()
                this.$notify({
                     
                  message: error.response.data.message  || error.response.data.error,
                  type: 'danger'
              })
           })
       }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card.card-cascade .view.gradient-card-header {
  padding: 1.1rem 1rem;
}

.card.card-cascade .view {
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
}
</style>
